import { React } from "react";
import NavBtns from "../components/NavBtns";
import LazyLoad from "react-lazy-load";

//import images
import BG from "../img/going-car-free/2016-0718-Axon.jpg";
import Stairs from "../img/going-car-free/SEPTA_15th_Headhouse1_HR_airxjl.png";
import Platform from "../img/going-car-free/SEPTA_15th_Platform1_HR_w1zcxu.png";
import RUS from "../img/going-car-free/3119_G2b_eve_usf9z7.jpg";
import Plaza88 from "../img/going-car-free/Translink-MT01199P-001_xlarge.jpg";
import Bayfront from "../img/going-car-free/JC_Bayfront_Redevelopment_77960.00.0_Plan_Site.jpg";
import Greenspace from "../img/going-car-free/JC_Bayfront_Redevelopment_77960.00.0.jpg";
import JCOther from "../img/going-car-free/JC_Bayfront_Redevelopment_77960.00.jpg";
import Landing from "../img/going-car-free/JC_Bayfront_Redevelopment_77960.00.0_Render_Ext_The_Point_org_qxhrkq.jpg";

function NewComponent(props) {
  return (
    <div className="page page-container going-car-free">
      <NavBtns prev="/its-showtime" next="/the-third-act" />
      <div className="hero-wrapper">
        <div className="hero">
          <div className="hero-text">
            <h1>
              <span className="park">Going</span>
              <span className="ride">Car-Free</span>
            </h1>
            <h4>
              Transit improvements and related developments are strengthening
              urban centers—and helping people steer clear of their
              carbon-emitting vehicles.
            </h4>
            <h6 className="by-line">by Jennifer Sergent</h6>
          </div>

          <div className="hero-right">
            <img
              src={BG}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1669136459/The%20Narrative%202022/2016-0718-Axon-Updated-R2_dszh5i.jpg"
              alt="SEPTA’s City Hall and 15th Street Stations"
            />
          </div>
        </div>
      </div>
      <h6 className="image-captions">
        A cutaway of SEPTA’s City Hall and 15th Street Stations in Philadelphia
        illustrates the intricacies involved with making accessible connections
        to three rail lines underneath a busy city intersection. Graphic
        Copyright BLTa
      </h6>
      <div className="section">
        <div className="static">
          <p className="caption">
            <br />
            “It’s hard to describe how difficult it is to put an elevator down
            there,” says Sherman Aronson, a senior associate with BLTa—A Perkins
            Eastman Studio, as he stands in front of the mammoth Second
            Empire-style Philadelphia City Hall. This 19th-century architectural
            marvel sits atop an engineering marvel: the city’s century-old
            subway and trolley system, with its four subterranean levels of
            zigzagging rails, platforms, concourses, and feet-thick,
            load-bearing walls—a complex never meant to accommodate an elevator
            shaft.
            <br />
          </p>
          <br />
        </div>
      </div>
      <div className="section">
        <div className="static">
          <p>
            Between City Hall Station and its two adjacent stops in Center City,
            Aronson and BLTa have been working with the Southeastern
            Pennsylvania Transportation Authority (SEPTA) for more than two
            decades to make two intersecting rail lines and a trolley line fully
            accessible. While the work was partially the result of a lawsuit
            under the Americans with Disabilities Act, its impact has been
            nothing short of staggering, from its engineering achievements to
            its urban placemaking successes, which have brought energy and life
            back to the heart of Center City. “When things are made better for
            those of us who have disabilities, it makes things better for
            everybody,” SEPTA Advisory Committee Member Denice Brown told WHYY
            in 2019, when the most recent of 10 new elevators were unveiled.
          </p>
          <p>
            The SEPTA project, and several transit-oriented developments Perkins
            Eastman and its specialized studios are guiding, illustrate the
            multiplier effect accessible public transportation can have on
            communities, reinvigorating old city centers and creating new ones.
          </p>
          <div className="two-img">
            <div className="single-img">
              <LazyLoad offset={300}>
                <img
                  src={Stairs}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669664942/The%20Narrative%202022/SEPTA_15th_Headhouse1_HR_airxjl.png"
                  alt="freestanding glass-walled enclosures for elevators and stairways"
                />
              </LazyLoad>

              <h6>
                BLTa—A Perkins Eastman Studio designed a series of freestanding
                glass-walled enclosures for elevators and stairways to take
                SEPTA passengers from street level down to three subway stations
                in Center City. Copyright Jeffrey Totaro/Courtesy BLTa
              </h6>
            </div>
            <div className="single-img">
              <LazyLoad offset={300}>
                <img
                  src={Platform}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1670865941/The%20Narrative%202022/SEPTA_15th_Platform1_HR_w1zcxu.png"
                  alt="backlit pillars in the 15th Street Station"
                />
              </LazyLoad>

              <h6>
                Glass artist Ray King created backlit pillars in the 15th Street
                Station that depict Philadelphia’s 1910 street map. Copyright
                Jeffrey Totaro/Courtesy BLTa
              </h6>
            </div>
          </div>
          <h3>SEPTA: Access Breeds Investment in Philadelphia</h3>
          <p>
            On a sweltering day last August, commuters and tourists used BLTa’s
            much-lauded, glass-enclosed elevators on the blocks surrounding City
            Hall to reach the subway. Workers inside 15 nearby office buildings
            took escalators, stairs, and elevators into the system without
            stepping foot into the summer heat. More elevators awaited near the
            ticket booths to ferry passengers to their platforms. Other
            pedestrians strolled down ramps to concourses that flow between the
            City Hall, Suburban, and 15th Street stations, which they could
            navigate without purchasing a ticket.
          </p>
          <p>
            This ease of access has been hard won, considering the massive
            interventions required to build ramps, elevators, and escalators
            throughout the old tunnels. And the toughest stage is still to come:
            making the City Hall Station itself fully accessible. The job
            entails installing more elevators, widening passageways and
            platforms, and removing walls between the tracks to improve
            visibility. It will require “teams of structural engineers,” Aronson
            says, to design and employ the extraordinarily advanced support
            systems needed to hold up City Hall, a National Historic Landmark
            and the largest freestanding masonry building in the world, while
            the work is being done. BLTa Associate Kyle Kernozek stands in the
            building’s shadow, pointing to the general location on the plaza
            where an elevator might be installed. Four are in the works for the
            City Hall Station, bringing the total to 14 on the street surfaces
            and between the underground platforms for all three stations. “It’s
            like a 3-D puzzle—what’s down underneath really dictates where each
            of them goes,” Kernozek says.
          </p>
          <p>
            The city has worked with developers, meanwhile, to remake the blocks
            above these three stations. The past decade has seen a major
            renovation with new landscaping at the city’s Love Park, which sits
            across from the Suburban Station, and a complete overhaul of
            Dilworth Park above the 15th Street Station, replacing an underused,
            two-level plaza in front of City Hall with new fountains, trees, and
            two glass canopies over subway entry stairs. “The renovations at
            Dilworth and Love Park have transformed the area into the city’s
            party room,” architecture critic Inga Saffron wrote in the
            Philadelphia Inquirer in 2020. The BLTa-led refurbishment of the
            brutalist, mixed-use Centre Square, which included opening up the
            plaza around Claes Oldenburg’s famous Clothespin sculpture, forms
            another vantage point with sight lines toward City Hall—and elevator
            access down to the 15th Street Station. “All of these improvements
            have made that part of Center City more usable, accessible, and
            visible,” Aronson says, adding that Dilworth Park is now the place
            where crowds gather to cheer victories by the city’s sports teams.
            “It becomes a celebratory location for major events,” he says.
          </p>
          <p>
            The act of traveling has itself become more enjoyable in the
            process—an additional lure for people to leave their cars and take
            public transit. At Suburban Station, the SEPTA upgrades have
            restored the 1930s-era Art Deco ticket booths, terrazzo floors, and
            ornate lighting, while the renowned local glass artist Ray King was
            commissioned to design platform columns at the 15th Street Station
            with backlit panels featuring a 1910 map of the city that predates
            the subway. Aronson says he loves walking up to strangers coming
            through that station. “I ask people, ‘Did you notice the art?’”
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={RUS}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136462/The%20Narrative%202022/3119_G2b_eve_usf9z7.jpg"
                alt="RUS Bus"
              />
            </LazyLoad>

            <h6>
              The base of the two-tower RUS Bus development will retain the
              brick facades of original structures in the heart of Raleigh’s
              Warehouse District. An artful steel bridge connecting to Union
              Station, along with street-level dining and retail, animates this
              new vertically integrated mixed-use, multimodal destination.
              Design by Perkins Eastman; Rendering by Interface Multimedia
            </h6>
          </div>
          <h3>RUS Bus: Catalyzing a Raleigh Resurgence</h3>
          <p>
            When the new Raleigh Union Station (RUS) opened for Amtrak rail
            service in 2018, it was hailed as a new “front door” to the city.
            But at the time, that door opened into a neighborhood that was more
            past than present. The Warehouse District, as it’s known for the
            19th-century brick structures that once served as industrial
            warehouses, was home to a cluster of vacant buildings, with a few
            pioneering restaurants and boutiques and a single, mixed-use office
            and residential building. Yet they provided the seeds for the
            renaissance currently underway. The regional GoTriangle bus and
            shuttle authority is building a new transit facility next to Union
            Station, which Perkins Eastman is designing along with a two-tower
            residential, restaurant, and retail development that integrates the
            two new transportation hubs with public space in between. “In the
            last 15 years, we’ve seen a dramatic resurgence of people wanting to
            live and work in downtown areas,” Perkins Eastman Principal Peter
            Cavaluzzi says. Raleigh Union Station provided the anchor that’s
            making the RUS Bus development feasible, he adds, noting that the
            new destination will likely be “the cornerstone of providing access
            to the city for residents and visitors alike.”
          </p>
          <p>
            Perkins Eastman’s experience with vertically integrated, mixed-use
            transit development made its profile particularly attractive to
            GoTriangle, which selected the design and development team. The
            firm’s design with RB Infrastructure—an arm of Hoffman &
            Associates—envisioned a creative mix of uses and public spaces that
            made RUS Bus a destination in its own right, providing a modern
            enclave that extends and enhances Union Station and the historic
            Warehouse District. “The design looks to integrate with the existing
            fabric of the neighborhood by maintaining the original warehouses’
            exterior walls at the base of the towers,” Perkins Eastman Principal
            and Executive Director Hilary Kinder Bertsch says, and “we’re
            embracing the transit as integral to the future of the project.
            We’re turning it into a positive by pulling new public environments
            through it. We’re transforming this hub into a multimodal facility.”
          </p>
          <div className="quote">
            <p>
              We’re embracing the transit as integral to the future of the
              project. We’re turning it into a positive by pulling new public
              environments through it. We’re transforming this hub into a
              multimodal facility.”
            </p>
            <h5>—Hilary Kinder Bertsch</h5>
          </div>

          <p>
            That approach meets the client’s goal of creating a defining
            transit-oriented development in a sprawling southern city trying to
            bring more people downtown and reduce their reliance on cars, says
            Stan Wall, a partner with HR&A Advisors who consults for GoTriangle.
            “This team has a track record of being able to create really great
            places, and that very much echoes what they’re doing here,
            transferring this asset into something that’s going to be catalytic
            for downtown Raleigh,” he says. The two new high-rises, the taller
            of which will reach 37 stories, will be among the highest buildings
            in downtown Raleigh. They will contain nearly 600 apartments with
            25,000 square feet of ground-floor retail and restaurants. “This is
            a skyline-changing project,” says Wall; RUS Bus “continues the
            momentum of getting people back into the city” by reducing sprawl
            and increasing density in what had been an underutilized section of
            the city. When it’s complete in 2026, RUS Bus will bring more than
            1,000 new residents to the city “because transit has made it more
            active and accessible,” Cavaluzzi believes. “The most successful
            transit developments attract people who aren’t even taking transit.”
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Plaza88}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1671458577/The%20Narrative%202022/Translink-MT01199P-001_xlarge.1558025795_neural_cropped_fjl722.jpg"
                alt="Plaza 88"
              />
            </LazyLoad>

            <h6>
              Plaza 88 in New Westminster, British Columbia, designed by VIA—A
              Perkins Eastman Studio, is a mixed-use development with integrated
              transit that sets a precedent for the Surrey-Langley project,
              where transit will be adjacent and accessible to new development
              planned along the SkyTrain extension. Copyright Blaine
              Campbell/Courtesy VIA
            </h6>
          </div>
          <h3>
            SkyTrain: Empowering Sustainable Regional Growth Across Metro
            Vancouver
          </h3>
          <p>
            This year, the Canadian province of British Columbia introduced
            legislative changes that will allow its transportation authority to
            purchase land and direct growth around planned expansions of its
            SkyTrain rail lines. “It will support the province’s efforts to
            build more housing for families, meet their climate targets, and
            unlock economic and social benefits for people across BC,” according
            to an announcement about the new legislation, and it will more
            directly integrate transportation and land use. VIA—A Perkins
            Eastman Studio is consulting with the province on its initial
            development planning and growth policy along a 16-kilometer (nearly
            10-mile) SkyTrain extension with eight new stops between Surrey,
            which lies about 30 minutes east of Vancouver, and Langley, a more
            exurban community farther east. “This is new—getting out ahead of
            the development around stations,” says VIA Principal Kokila Lochan.
            “It’s a big deal.”
          </p>
          <p>
            Surrey and Langley have relatively low-density residential areas,
            Lochan says, and they are interspersed with typical, mostly
            car-reliant suburban strip malls. But this is one of the
            fastest-growing areas in Vancouver’s metropolitan area. By 2050,
            according to figures from Metro Vancouver, the Surrey-Langley
            region’s population is expected to increase by 402,700 to nearly 1.2
            million, with more than 155,000 new jobs that will bring the area’s
            total to 465,200. Given that the cities are constrained by Metro
            Vancouver’s urban containment boundary, which forbids growth into
            undeveloped areas beyond its lines, the only way to grow is up, VIA
            Principal Douglas Adams says. That means higher-density housing and
            mixed-use commercial districts are crucial to the area’s growth and
            long-term sustainability, which will ultimately reduce
            greenhouse-gas emissions. The development anticipated for the areas
            adjacent to the rail extension includes housing, business, retail,
            restaurants, schools, childcare, healthcare, public spaces, and
            outdoor recreation. The goal, according to the British Columbia
            announcement, is to create “livable, compact communities, making it
            convenient for pedestrians, cyclists, or people who use mobility
            aids to access the nearest transit hub.”
          </p>
          <p>
            “This is steering development,” Adams says. “It turns how things are
            typically done in the United States on its head,” he adds, referring
            to projects that “shoehorn” transit, one station at a time, into
            existing development—not the other way around. “That’s why the
            [SkyTrain] system is really doing something different,” Lochan adds.
            “It’s anticipating growth and need. It’s helping to create place,
            and it’s changing policy to make it occur systematically.” By
            allowing residents to find good jobs, housing, and amenities in
            their existing communities, the endeavor will not only relieve
            traffic congestion, but also create well-planned urban centers,
            which are desirable in their own right. Says Lochan, “This is the
            visionary work that really changes cities.”
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Bayfront}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668630483/The%20Narrative%202022/JC_Bayfront_Redevelopment_77960.00.0_Plan_Site_org_qflic8.jpg"
                alt="bayfront site"
              />
            </LazyLoad>

            <h6>
              The site plan for the new Bayfront development features 19 acres
              of park space. A new light-rail station to the north and a
              water-taxi transit pier extending from the riverfront promenade
              are within walking distance. Rendering Copyright Perkins Eastman
            </h6>
          </div>
          <h3>
            Bayfront: From Toxic Brownfield to Heralded Waterfront Community in
            Jersey City
          </h3>
          <p>
            To the extent most people know Jersey City, it’s by the shiny Gold
            Coast skyline along the Hudson River, with views toward Manhattan’s
            financial district and the Statue of Liberty. “Jersey City’s been
            really booming. It’s kind of like Brooklyn—it’s taken off as housing
            has become less affordable in Manhattan,” Perkins Eastman Principal
            Eric Fang says. Yet with luxury properties such as the Perkins
            Eastman-designed 99 Hudson, where the penthouse recently sold for
            $4.4 million, the Hudson side of Jersey City is becoming
            increasingly out of reach too. The new frontier lies to the west,
            where the Hackensack River separates Jersey City and Newark. “The
            Hackensack is kind of the forgotten river,” says Fang, who leads the
            firm’s Planning and Urban Design practice. He’s helping Jersey City
            with a master-plan for a new mixed-use, mixed-income development on
            a 100-acre site. Bounded on two sides by the Hackensack, the
            property was once home to a chemical plant. When the plant closed in
            1954, it left behind toxic soil, and the land sat empty until now,
            following years of lawsuits that culminated in a government-mandated
            cleanup.
          </p>
          <p>
            In a move that’s unusual in the United States—and more similar to
            the work British Columbia is doing with its SkyTrain extension and
            associated development—Jersey City issued bonds to buy the land
            (from the Honeywell Corporation) to control how it’s developed. As a
            result, 35 percent of an expected 8,000 residential units will be
            affordable, and they’ll be oriented around 19 acres of open space,
            including a public river walkway and boat launch. The community will
            also include offices, shops, a new school, and a firehouse. The
            entire development, from its infrastructure and landscape design to
            its architectural requirements, is intended to be energy efficient,
            environmentally sustainable, and resilient in the face of climate
            change.
          </p>
          <div className="static">
            <div className="two-img">
              <div className="single-img" id="left">
                <LazyLoad offset={300}>
                  <img
                    src={Greenspace}
                    // src="https://res.cloudinary.com/nickpug/image/upload/v1668630492/The%20Narrative%202022/JC_Bayfront_Redevelopment_77960.00.0_Render_Ext_Bayfront_Central_Park_1_org_y7faiz.jpg"
                    alt="bayfront"
                  />
                </LazyLoad>{" "}
              </div>{" "}
              <div className="single-img" id="right">
                <LazyLoad offset={300}>
                  <img
                    src={JCOther}
                    // src="https://res.cloudinary.com/nickpug/image/upload/v1668630520/The%20Narrative%202022/JC_Bayfront_Redevelopment_77960.00.0_Render_Ext_Wetland_Waterfront_org_zytfzu.jpg"
                    alt="bayfront"
                  />
                </LazyLoad>
              </div>
            </div>
            <h6>
              Bayfront is planned to be walkable, with easy access to green
              space (left) and public transit. It also aims to create a vibrant
              new destination along Jersey City’s “other riverfront” (right),
              designed to bolster the site’s resilience by creating a living
              shoreline with opportunities for native species and wildlife.
              Renderings Copyright Perkins Eastman
            </h6>
          </div>
          <p>
            Crucial to the project’s success is its transit access. The city
            will extend the Hudson-Bergen light-rail line into Bayfront,
            providing connections to points east into Manhattan. A water-taxi
            service is also envisioned to ferry residents across the Hackensack
            to job centers such as Newark International Airport, the ports of
            Newark and Elizabeth, and the Kearny Point business district. As
            opposed to piecemeal development over many years, Fang says, “this
            is a case where transit and housing and land use work together.”
            Transit, however, is the linchpin. If residents can’t easily reach
            jobs and other opportunities, Fang says, “you’re creating another
            problem.”
          </p>
          <p>
            In July, the Jersey City Redevelopment Agency’s (JCRA) Board of
            Commissioners voted unanimously to approve plans for Bayfront’s
            first development, a six-story building with 209 residential units
            (35 percent are designated for households making less than 60
            percent of the area’s median income), along with community event
            space, resident amenities, and retail. “We are seeing incredible
            progress as we transform the hundred acres of underutilized and
            remediated land into a welcoming and affordable community along the
            Hackensack River waterfront,” JCRA Executive Director Diana Jeffrey
            said in a statement. Construction is expected to begin in 2023.
          </p>
        </div>
      </div>

      <div className="section">
        <div className="static">
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Landing}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136595/The%20Narrative%202022/JC_Bayfront_Redevelopment_77960.00.0_Render_Ext_The_Point_org_qxhrkq.jpg"
                alt="transit pier at Bayfront"
              />
            </LazyLoad>

            <h6>
              A ferry landing will anchor the planned water-taxi transit pier at
              Bayfront. Rendering Copyright Perkins Eastman
            </h6>
          </div>
          <h3>Changing Hearts and Minds</h3>
          <p>
            Designers and planners throughout Perkins Eastman and its
            specialized studios are committed to creating high-quality urban
            planning and transit projects that encourage the development of
            walkable communities, where housing is affordable and transit is
            easily accessible. The challenge is particularly great in light of
            statistics showing a post-COVID surge away from urban counties and
            into suburban and exurban areas, according to a March 2022 report by
            the Economic Innovation Group. Lack of space and affordability in
            urban counties were driving factors, the report states. At the same
            time, a 2020 report by Smart Growth America found that, as climate
            change worsens each year, transportation accounts for the largest
            share of carbon emissions in the United States—and car-dependent
            suburbs and exurbs only exacerbate the problem:
          </p>
          <p className="indent">
            Where we live and how we get where we need to go is no accident. It
            is the result of a series of decisions made at the federal, state,
            and local levels of government, ranging from zoning laws to
            subsidies for gasoline to the low level of federal support for
            public transit. . . . Market demand for compact, connected,
            walkable, mixed-use neighborhoods continues to outpace supply by a
            very large amount, making those neighborhoods unaffordable to even
            the middle class, much less those who can’t afford a car. Simply
            put, we’ll never achieve ambitious climate targets or create more
            livable and equitable communities if we don’t find ways to allow
            people to get around outside of a car.
          </p>
          <p>
            As Perkins Eastman works with state agencies, municipal governments,
            transportation agencies, and developers to address these issues at
            the policy, planning, and project levels, the firm is designing a
            road map to reverse living and commuting trends that heavily favor
            cars—and, in the process, meet the surging demand for walkable,
            mixed-use urban communities. <strong>N</strong>
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
