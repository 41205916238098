import { React } from "react";
import NavBtns from "../components/NavBtns";
import LazyLoad from "react-lazy-load";

//import images
import BG from "../img/the-third-act/V2_RH-ExteriorAmenity.jpg";
import Enso from "../img/the-third-act/Enso_Village_-_SF_Zen_Center_80290.00.0_Render_Int_Unit_Living_Room.jpg";
import Life from "../img/the-third-act/66571_00_0_N33.jpg";
import Aegis from "../img/the-third-act/LS20150807_aegismadison_008_pwchjt.png";
import Paradise from "../img/the-third-act/57120_00_0_N27_mr_utafv6.jpg";
import Trillium from "../img/the-third-act/68125_00_0_N20_mr_fbdo8c.jpg";

function NewComponent(props) {
  return (
    <div className="page page-container the-third-act">
      <NavBtns prev="/going-car-free" next="/take-five" />
      <div className="hero-wrapper">
        <div className="hero">
          <div className="hero-image">
            <img
              src={BG}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671069092/The%20Narrative%202022/V2_RH-ExteriorAmenity_041222_uv8bra.jpg"
              alt="Priya Living in Rochester Hills, MI"
            />
          </div>
          <div className="hero-text">
            <h2>Living Our Best Life</h2>
            <h1>The Third Act</h1>
          </div>
        </div>
      </div>
      <h5>
        The new Priya Living in Rochester Hills, MI, offers a welcoming embrace
        to residents and visitors of all ages with a partially covered terrace
        and recreation area adjacent to its canopied entry. Rendering Courtesy
        Priya Living
      </h5>
      <div className="section">
        <div className="static">
          {" "}
          <h6>
            As the senior population stays healthier longer and grows more
            diverse, two communities distinguish themselves as they reflect
            their residents’ ethnic and spiritual identities.
          </h6>
          <h6 className="by-line">by Jennifer Sergent</h6>
          <p className="caption">
            Arun Paul wasn’t looking to create a transformative new living
            option for the legions of retiring Indian and South Asian immigrants
            who came to the United States in the late 1960s. He just knew that
            his parents, who had moved from Kolkata to California before he was
            born, weren’t comfortable with any of the senior living offerings
            close to Paul’s home in the San Francisco Bay Area. “They didn’t say
            anything,” he remembers of the tours he took with his parents
            through several communities, “but I felt [their concern] at every
            level: It’s not welcoming.”
          </p>
          <p>
            His response? The resort and hospitality executive decided he would
            purchase a duplex for his parents and their best friends so they
            could form their own small community. But as his parents started
            telling friends and relatives across the country what he was doing
            for them, the story took on a life of its own. Before Paul knew it,
            he was getting hundreds of calls. A typical caller would say, “I’m
            your mom’s cousin’s best friend, and I heard you’re building a
            senior living community for Indians!” One person even said: “We’ve
            been talking about this in Atlanta for 20 years—God bless you.” What
            Paul was hearing is representative of a change that’s happening
            across the senior living sector, where developers are focusing on
            the interests, lifestyles, ethnicity, and backgrounds of their
            prospective residents rather than simply building a place to house
            and feed them and attend to their medical needs. Perkins Eastman
            Principal and Senior Living Co-Practice Leader Martin Siefering
            suggests that viewing our future in the context of the past can be
            helpful. With shorter life expectancies and fewer financial
            resources, especially prior to the creation of Social Security, he
            says, “previous generations of older adults were often content with
            quite minimal shelter and care.” Today, senior living communities
            across the country that were designed to meet those modest needs are
            now struggling to find relevance in today’s environment. “We also
            have a population of older adults today that is much more highly
            educated than previous generations, especially among women,”
            Siefering adds. “They’ve lived their lives with broader experiences,
            and as a result are demanding a different approach to housing as
            they age.”
          </p>{" "}
          <div className="quote">
            <p>
              Adults who are living longer will no longer be resting; they are
              active and a vital part of our society, and our living
              environments will need to reflect those seismic shifts.”
            </p>
            <h5>—“The Longevity Revolution”</h5>
          </div>
          <p>
            People are living longer and staying healthier, so illness and
            frailty are no longer the only drivers of senior-living design. And
            though there continues to be substantial demand for assisted-living,
            skilled nursing, and memory-support environments, there’s an
            increasing push to develop independent-living communities that are
            rich in amenities and activities. “Adults who are living longer will
            no longer be resting; they are active and a vital part of our
            society, and our living environments will need to reflect those
            seismic shifts,” Perkins Eastman Principal Leslie Moldow and Senior
            Associate Merintha Pinson write in “The Longevity Revolution,” a
            white paper they produced earlier this year. “Developers can
            identify niche markets and tailor life plans to people with shared
            values—whether spiritual, cultural, or personal interests.”
          </p>
          <p>
            Two developers—Paul and Kendal Corporation’s Sean Kelly—are tapping
            into these “seismic shifts” with vibrant, community-centered
            options. Their projects offer creative best practices for the
            burgeoning senior living market.
          </p>
        </div>
      </div>
      <div className="parallax priya"></div>

      <p className="parallax-caption">
        Bold colors characterize the interiors of Priya Living in Rochester
        Hills, MI, where the main level features a chai bar, left, and the Masti
        Lounge through an arched entry beyond—a space named after the Hindi term
        for playful and fun. Rendering Courtesy Priya Living
      </p>
      <div className="section">
        <div className="static">
          <h3>Answering the Call</h3>
          <p>
            After hearing from so many of his parents’ friends, friends of
            friends, and acquaintances, Paul nixed the duplex idea and instead
            purchased a 26-unit apartment complex in Santa Clara, where he
            opened the first Priya Living community in 2012 and served nearly
            every role, including sales director, head of maintenance, and even
            food-delivery driver. He also traveled across the country, holding
            listening sessions attended by hundreds of people at each stop. They
            told impassioned stories about their desires to replicate the
            culture and community in later life that they had already
            established in the broader Indian diaspora throughout the US. “I
            felt this my whole life, like my parents left a piece of themselves
            behind in India. This is true across cultures,” Paul says. “That’s
            why I see an enormous need for this. It’s about creating places
            where our residents feel understood and valued.” Priya Living now
            has four locations, totaling 256 units between San Francisco and San
            Jose, all of which are sold out. To further expand the concept, Paul
            hired Perkins Eastman to design new, amenity-driven communities near
            Detroit and Houston, and most of the apartments have been pre-leased
            though construction has yet to begin on either project. Paul is
            already envisioning more locations in Chicago, Atlanta, Northern
            Virginia, Philadelphia, New Jersey, Boston, Dallas, and Austin.
          </p>
          <p>
            The designs for each of these properties nod to Indian culture, with
            large, open, and flexible spaces to accommodate gatherings such as
            parlor games, book clubs, Bollywood movie nights, karaoke, and
            programming to bring other generations of Indians into the
            community. There’s also a chai bar, spaces for yoga and meditation,
            and a vegetarian-based dining menu. The color palette is inspired by
            bright saris and Indian spice markets. Paul is quick to point out,
            however, that Priya Living communities are open to anyone who favors
            the lifestyle they offer, not just those of Indian or South Asian
            descent. “Our goal has always been, no matter who you are, you can
            come into our community and say, ‘I love the vibe here,’” he says.
            “We’re people focused, not product focused.”
          </p>
          <div className="images-single">
            <LazyLoad>
              <img
                src={Enso}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669817925/The%20Narrative%202022/Enso_Village_-_SF_Zen_Center_80290.00.0_Render_Int_Unit_Living_Room_org_udwvqf.jpg"
                alt="Condominium at Enso Village"
              />
            </LazyLoad>
            <h5>
              Condominium owners at Enso Village can choose between three finish
              schemes—all based on warm neutrals in keeping with the rest of the
              building’s interiors. Residents will enjoy large windows and
              balconies overlooking the inner courtyard, the surrounding
              neighborhood, or the hills of Healdsburg. Rendering Courtesy Green
              Grass Studios
            </h5>
          </div>
          <h3>Intentional Living</h3>
          <p>
            Priya offers an array of wellness and personal development programs
            such as an incubator for career coaching, mentorship exchange,
            volunteer opportunities, and continued learning. Its residents
            represent a “new customer,” according to Robert Kramer, co-founder
            and strategic advisor of the National Investment Center for Seniors
            Housing & Care. In an August 2022 article about the drivers shaping
            the future of senior living, he writes that long life in the last
            century happened almost by accident: “Our society is, frankly, built
            for people to retire and die in their 60s and 70s. Our norms and
            expectations around retirement and aging, not to mention our social
            insurance system, are not designed for the longevity reality that
            we’re facing today,” Kramer writes. This “new customer” is among the
            first generation ever to experience purposeful longevity. “What
            personalized experiences that are metaphors for being alive or make
            life worth living will we offer our customers? That is our
            challenge.”
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <p>
            Perkins Eastman is well positioned to meet the moment. Almost since
            its founding in 1981, the firm has worked with faith-based
            organizations that developed communities geared toward their
            residents’ quality of life rather than merely their healthcare or
            aging-related needs. Prominent among these organizations were—and
            still are—Jewish communities across the country who’ve built
            retirement homes for their members. “They’re based on the
            commandment to honor thy father and thy mother,” says Perkins
            Eastman Co-founder and Chairman Brad Perkins. Most retirement homes
            in the 1980s tended to be focused on skilled nursing, he says, but
            Jewish senior living facilities stood out because they were often
            the best facilities in their region. “They had good philanthropic
            support and creative leadership, and they tended to be showcases.
            They were driving innovation, particularly in some very core areas,
            which are the dignity and the privacy of every single resident,”
            Perkins says. Paul says he looked to Jewish communities as a model
            for Priya Living. “They show the power of connection and shared
            interests,” he says. “I could see very clearly what it was doing for
            people—it was acknowledging who they were. It was saying, ‘Yes, you
            matter. You’re important.’ It was creating a place where you felt
            safe, where people understood you.”
          </p>
          <p>
            The same concept is at work in Healdsburg, CA, where Perkins Eastman
            is providing interior architecture and design for Enso Village, a
            Zen-inspired community the Kendal Corporation is developing in
            partnership with the San Francisco Zen Center. “They looked to us to
            bring both our expertise and storytelling, narrative approach to
            design, to think about this community differently,” says Emily Woods
            Weiskopf, associate principal and the Residential co-practice area
            leader for ForrestPerkins, a distinct Perkins Eastman studio. The
            Zen Center long ago made a promise to provide retirement housing to
            its 20 original meditation teachers. To build a community for those
            teachers and others who appreciate a spiritual, contemplative
            lifestyle, they approached the Quaker-based Kendal, whose values of
            consensus and openness align with Zen practices. The focus is “to
            meet people where they are with an openness to where they might go,”
            Kendal President and CEO Sean Kelly says in an introductory video on
            the Enso Village website. “The world at-large looks at getting older
            through a very narrow view. For the most part, that narrow view is
            only through the decline, and that’s why this community . . . is
            getting so much traction.” Its 275 condominiums were 95-percent sold
            before the 2021 ground breaking; the project is expected to open in
            2023.
          </p>
          <p>
            In keeping with the rural Healdsburg landscape, the designers styled
            the interiors at Enso Village with a nature-inspired scheme that
            includes a Japanese tea garden and a “zendo” meditation hall in the
            center of a large courtyard; vegetable gardens to stock its
            kitchens; and an all-vegetarian bistro based on the Zen Center’s
            Greens restaurant in San Francisco. Interior “nodes” at the
            intersection of each connecting building in the complex provide
            space for tranquil pause. “The interior design feels timeless and
            true to its story,” Woods Weiskopf says.
          </p>
          <p>
            “The New Map of Life,” a multiyear study published by the Stanford
            Center on Longevity in 2021, envisions “a society that supports
            people to live secure and high-quality lives for a century or more.”
            Priya Living and Enso Village answer the call with environments that
            focus on vitality and wellness rather than decline. “This is the
            future,” says Moldow, a leader in the Senior Living practice. “There
            are so many more people staying healthy longer that we’re able to
            diversify and create these more targeted communities.”{" "}
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="quote">
            <p>
              The world at-large looks at getting older through a very narrow
              view. For the most part, that narrow view is only through the
              decline, and that’s why this community . . . is getting so much
              traction.”
            </p>
            <h5>—Sean Kelly, Kendal President and CEO</h5>
          </div>
        </div>
      </div>
      <div className="section active-generation">
        <div className="static">
          <h4>
            <span className="ag-text">Preparing for an</span>
            <br />
            <span className="ag"> Active generation</span>
          </h4>
          <p>
            “From Pyramid to Pillar: A Century of Change,” a recent US Census
            Bureau population projection, shows the rapid evolution of age
            distribution between 1960, when people over 60 formed an
            increasingly smaller portion of the population, and 2060, when this
            group’s numbers are projected to nearly equal their youngest
            counterparts. Pair that trend with advances in health and medicine,
            and centenarians’ birthdays will no longer be deemed feature-worthy
            on the evening news.
          </p>
          <p>
            {" "}
            “The New Map of Life” by the Stanford Center on Longevity says
            “longevity-ready” communities will have to prepare for “longer human
            lives, with built environments that are more walkable and provide
            access to mass transit, healthcare, and opportunities for lifelong
            learning, and that foster intergenerational connections.”
          </p>
          <p>
            {" "}
            Living environments are already starting to respond. “The consumer
            of today is incredibly diverse in what they want, and that expresses
            itself in many ways,” says Perkins Eastman Principal Martin
            Siefering, the co-practice leader for Senior Living. Here are some
            recent examples of firm-designed communities that respond to the
            needs and desires of this new generation of seniors:
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Life}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136470/The%20Narrative%202022/66571_00_0_N33_mr_adsdgc.jpg"
                alt="2Life Communities"
              />
            </LazyLoad>
            <p>Rendering Copyright Perkins Eastman</p>
          </div>
          <p>
            {" "}
            2Life Communities: Opus Newton | Newton, MA This middle-income
            community, being built next to an existing 2Life community for
            low-income residents, is modeled on a “kibbutz” where every resident
            volunteers for service hours in activities of their choice. As a
            result, everyone is invested in actively maintaining their
            community, and the program keeps its operating costs to a minimum.{" "}
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Aegis}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1671112932/The%20Narrative%202022/LS20150807_aegismadison_008_pwchjt.png"
                alt="Aegis Madison"
              />
            </LazyLoad>
            <p>Copyright Lara Swimmer/Courtesy VIA</p>
          </div>
          <p>
            Aegis Madison | Seattle, WA Located in the middle of Seattle’s
            Capitol Hill neighborhood, Aegis blends seamlessly with the
            surrounding community; it opens its common areas to private events
            and local community meetings, and it operates the public Queen Bee
            coffeehouse and a mercantile boutique on its ground level.
          </p>
          <p>
            Inspirata Pointe at Royal Oaks | Sun City, AZ Wearable technology
            and blood-sugar monitors for its residents, along with a hands-on
            community garden and an abundance of nutritional food options, help
            residents stay active participants in their own health.{" "}
          </p>
          <p>
            Maravilla at The Domain | Austin, TX Its ZEST wellness program
            offers lifelong learning seminars, whole-food and plant-based menu
            options, gardening groups, fitness programs, and opportunities for
            volunteering in the community, such as mentoring an elementary
            school class.{" "}
          </p>
          <p>
            MonteCedro | Altadena, CA This resort-style community near Los
            Angeles offers a Creative Living Academy, where residents can
            “teach, lead, or study a vast array of subjects, from art history to
            current events to horticulture.”{" "}
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Paradise}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136470/The%20Narrative%202022/57120_00_0_N27_mr_utafv6.jpg"
                alt="Paradise Valley Estates"
              />
            </LazyLoad>
            <p>Rendering Copyright Perkins Eastman</p>
          </div>
          <p>
            Paradise Valley Estates: The Ridge | Fairfield, CA An eight-acre
            addition was designed to encourage movement and staying active, with
            walking paths, community gardens, and a woodworking studio.{" "}
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Trillium}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136472/The%20Narrative%202022/68125_00_0_N20_mr_fbdo8c.jpg"
                alt="Trillium of Tysons"
              />
            </LazyLoad>
            <p>Rendering Copyright Perkins Eastman</p>
          </div>
          <p>
            Trillium of Tysons | Tysons, VA This high-rise, currently under
            construction, is located within a mixed-use development at the
            center of Tysons, an urban outpost of Washington, DC. The project is
            one block from the Metro, with direct access to multiple
            restaurants, shops, and cinemas.{" "}
          </p>
          <p>
            Vincentian Schenley Gardens | Pittsburgh, PA This model of
            intergenerational care keeps its residents connected to other age
            groups. Graduate students in local health-sciences programs provide
            in-person support, and residents are able to volunteer at the
            on-site childcare program. <strong>N</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
